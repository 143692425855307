<template>
  <v-container grid-list-md>
    <v-toolbar>
      <v-toolbar-title>Select Option Editor</v-toolbar-title>
      <v-spacer></v-spacer>
      <new-list-option @created="({ _id }) => $router.push('/forms/admin/options/' + _id)"></new-list-option>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="filtersOpen = !filtersOpen">
            <v-icon>{{ filtersActive ? 'fas' : 'fal' }} fa-filter</v-icon>
          </v-btn>
        </template>
        <span>View Filters</span>
      </v-tooltip>
    </v-toolbar>
    <v-card v-if="filtersOpen">
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field v-model="nameFilter" label="Name"></v-text-field>
          </v-col>
          <v-col>
            <v-select v-model="activeFilter" :items="[{ text: 'All', value: null }, { text: 'Active', value: true }, { text: 'Inactive', value: false }]" label="Status"></v-select>
          </v-col>
          <v-col>
            <v-text-field v-model="creatorFilter" label="Created By"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="editorFilter" label="Last Edited By"></v-text-field>
          </v-col>
          <v-col>
            <v-select v-model="typeFilter" :items="[{ text: 'All', value: '' }, { text: 'Static List', value: 'static' }, { text: 'Dynamic List', value: 'dynamic' }]" label="Option Type"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table :items="optionLists" :headers="optionListsHeaders" :server-items-length="listCount" :options="pagination" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 25] }" @update:options="loadLists">
      <template v-slot:item.updatedAt="{ item }">{{ stringFormatDate(item.updatedAt) }}</template>
      <template v-slot:item.detail="{ item }">{{ item.type === 'static' ? item.count : item.service }}</template>
      <template v-slot:item.view="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon :to="'/forms/admin/options/' + item.value">
              <v-icon>fal fa-eye</v-icon>
            </v-btn>
          </template>
          <span>View or Edit Option List</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
export default {
  components: {
    newListOption: () => import('@/components/forms/options/newOption')
  },
  setup (props, { root }) {
    const optionLists = ref([])
    const optionListsHeaders = ref([
      { text: 'Name', value: 'name' },
      { text: 'Created By', value: 'createdBy' },
      { text: 'Last Edited By', value: 'updatedBy' },
      { text: 'Last Edited Date', value: 'updatedAt' },
      { text: 'Type', value: 'type' },
      { text: 'Options/Service', value: 'detail', sortable: false },
      { text: 'View', value: 'view', sortable: false }
    ])

    const selected = ref(false)
    const listCount = ref(0)

    const service = root.$feathers.service('forms/select-options')

    onMounted(() => {
      loadLists()
    })

    const pagination = computed({
      get: () => root.$store.state.forms.admin.options.pagination,
      set: (obj) => root.$store.commit('forms/setAdminOptionPagination', obj)
    })
    function loadLists (options) {
      if (options) {
        root.$store.commit('forms/setAdminOptionPagination', options)
      }
      const { page, itemsPerPage, sortBy, sortDesc } = pagination.value
      let query = {
        $skip: (page - 1) * itemsPerPage,
        $limit: itemsPerPage
      }
      if (sortBy.length > 0) {
        query.$sort = {}
        for (let i = 0; i < sortBy.length; i++) {
          query.$sort[sortBy[i]] = sortDesc[i] ? -1 : 1
        }
      } else {
        query.$sort = { name: 1 }
      }
      if (nameFilter.value !== '') {
        query.name = { $regex: nameFilter.value, $options: 'i' }
      }
      if (activeFilter.value != null) {
        if (activeFilter.value) query.active = { $in: [null, true] }
        else query.active = false
      }
      if (creatorFilter.value !== '') {
        query.createdBy = { $regex: creatorFilter.value, $options: 'i' }
      }
      if (editorFilter.value !== '') {
        query.editedBy = { $regex: editorFilter.value, $options: 'i' }
      }
      if (typeFilter.value !== '') {
        query.type = typeFilter.value
      }
      service.find({ query }).then(({ total, data }) => {
        listCount.value = total
        optionLists.value = data
      })
    }

    const filtersOpen = ref(false)
    const nameFilter = computed({
      get: () => root.$store.state.forms.admin.options.filters.name,
      set: (name) => {
        root.$store.commit('forms/setAdminOptionFilters', { name })
        loadLists()
      }
    })
    const activeFilter = computed({
      get: () => root.$store.state.forms.admin.options.filters.active,
      set: (active) => {
        root.$store.commit('forms/setAdminOptionFilters', { active: active == null ? '' : active })
        loadLists()
      }
    })
    const creatorFilter = computed({
      get: () => root.$store.state.forms.admin.options.filters.createdBy,
      set: (createdBy) => {
        root.$store.commit('forms/setAdminOptionFilters', { createdBy })
        loadLists()
      }
    })
    const editorFilter = computed({
      get: () => root.$store.state.forms.admin.options.filters.editedBy,
      set: (editedBy) => {
        root.$store.commit('forms/setAdminOptionFilters', { editedBy })
        loadLists()
      }
    })
    const typeFilter = computed({
      get: () => root.$store.state.forms.admin.options.filters.listType,
      set: (listType) => {
        root.$store.commit('forms/setAdminOptionFilters', { listType })
        loadLists()
      }
    })
    const filtersActive = computed(() => {
      return nameFilter.value !== '' || creatorFilter.value !== '' || editorFilter.value !== '' || typeFilter.value !== ''
    })

    return {
      optionLists,
      optionListsHeaders,
      selected,
      listCount,
      pagination,
      loadLists,
      filtersActive,
      nameFilter,
      activeFilter,
      creatorFilter,
      editorFilter,
      typeFilter,
      filtersOpen,
      stringFormatDate
    }
  }
}
</script>
